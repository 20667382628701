import BaseIcon from "@/components/UI/BaseIcon";

export default {
  name: "oco-order-info-modal",
  components: {
    BaseIcon,
  },
  props: {
    showModal: {
      type: Boolean,
    },
  },
  data() {
    return {
      ocoOrderType: "buy",
    };
  },
  computed: {},
  mounted() {},
  methods: {},
};
