import { render, staticRenderFns } from "./MarketTable.html?vue&type=template&id=0e42c915&scoped=true&"
import script from "./MarketTable.js?vue&type=script&lang=js&"
export * from "./MarketTable.js?vue&type=script&lang=js&"
import style0 from "./MarketTable.scss?vue&type=style&index=0&id=0e42c915&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e42c915",
  null
  
)

export default component.exports