export default {
  data() {
    return {
      currentT: 0,
      day: "00",
      hour: "00",
      minutes: "00",
      seconds: "00",
      timer: "",
      flag: false,
      state: 0, // 0: The event has not started 1: The event has started but not ended 2: The event has ended but not yet ended 3: The event has ended in liquidation
    };
  },
  mounted() {
    this.timer = setInterval(() => {
      if (this.flag == true) {
        clearInterval(this.timer);
      } else {
        this.timeDown();
      }
    }, 1000);
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  props: {
    countDownBgColor: {
      type: String,
    },
    publishState: {
      type: Number,
    },
    publishType: {
      type: String,
    },
    currentTime: {
      type: Number,
    },
    startTime: {
      type: String,
    },
    endTime: {
      type: String,
    },
    clearTime: {
      type: String,
    },
    showPublishMask: {
      type: Function,
      default: null,
    },
    hidePublishMask: {
      type: Function,
      default: null,
    },
    hideCountDown: {
      type: Function,
      default: null,
    },
  },
  methods: {
    timeDown() {
      var endTime = 0;

      const nowTime = parseInt(this.currentT + this.currentTime);
      const startT = parseInt(new Date(this.startTime).getTime() / 1000);
      const endT = parseInt(new Date(this.endTime).getTime() / 1000);
      const clearT = parseInt(new Date(this.clearTime).getTime() / 1000);

      if (nowTime <= clearT) {
        this.state = 2;
        endTime = clearT;
      }
      if (nowTime <= endT) {
        this.state = 1;
        endTime = endT;
      }
      if (nowTime <= startT) {
        this.state = 0;
        endTime = startT;
      }

      let leftTime = endTime - nowTime;
      this.day = this.formate(parseInt(leftTime / (24 * 60 * 60)));
      this.hour = this.formate(parseInt((leftTime / (60 * 60)) % 24));
      this.minutes = this.formate(parseInt((leftTime / 60) % 60));
      this.seconds = this.formate(parseInt(leftTime % 60));
      if (this.state == 0) {
        // activity has not started
        // this.showPublishMask();
        this.$emit("update:countDownBgColor", "#003478");
        this.$emit("update:publishState", 0);
      }
      if (this.state == 1) {
        // activity is starting
        this.hidePublishMask();
        this.$emit("update:countDownBgColor", "#094802");
        this.$emit("update:publishState", 1);
      }
      if (this.state == 2) {
        // in liquidation
        this.showPublishMask();
        this.$emit("update:countDownBgColor", "#5b0000");
        this.$emit("update:publishState", 2);
      }
      if (this.state == 2 && leftTime <= 0) {
        // End of liquidation, normal trading
        this.hidePublishMask();
        this.hideCountDown();
        this.flag = true;
        this.state = 3;
        this.$emit("update:publishState", 3);
      }
      this.currentT = this.currentT + 1;
    },
    formate(time) {
      if (time >= 10) {
        return time;
      } else {
        return `0${time}`;
      }
    },
  },
};
