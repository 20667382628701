import { mapGetters, mapActions } from "vuex";
import Loader from "@/components/UI/LoadingComp"

export default {
  name: "exchange-order-tables",
  components: {
    Loader
  },
  props: {
    openOrdersTableData: {
      type: Array,
    },
    ordersHistoryTableData: {
      type: Array,
    },
    preconditionsHistoryData: {
      type: Array,
    },
    stopOrdersTableData: {
      type: Array,
    },
    currentOrdersLoading: {
      type: Boolean
    },
    historyOrdersLoading: {
      type: Boolean
    },
    preconditionsHistoryLoading: {
      type: Boolean
    },
    preconditionOrdersLoading: {
      type: Boolean
    }
  },
  data() {
    return {
      cancelOrderModal: false,
      cancelOrderId: "",
      orderStopLimitDetailsData: [],
      isOrderStopLimitModalOpen: false,
      isPreconditionOrderCanceled: false,
      PreconditionOrderCanceledId: "",
      isOrderDetailModalOpen: false,
      orderDetailsData: [],
      selectedOrder: "current",
      detailsBtnLoading: null,
      openOrdersColumns: [
        {
          type: "index",
          minWidth: 40,
          render: (h, params) => {
            return h(expandRow, {
              props: {
                skin: params.row.skin,
                rows: params.row.detail,
              },
            });
          },
        },
        {
          title: this.$t("exchange.time"),
          sortable: true,
          minWidth: 200,
          align: "center",
          key: "time",
          render: (h, params) => {
            return h(
              "span",
              {},
              this.$options.filters.dateFormat(params.row.time)
            );
          },
        },
        {
          title: this.$t("exchange.symbol"),
          key: "symbol",
          minWidth: 120,
          align: "center",
        },
        {
          title: this.$t("exchange.orderType"),
          minWidth: 140,
          align: "center",
          render: (h, params) => {
            return h(
              "span",
              params.row.source === "STOP_LIMIT_ORDER" ||
                params.row.source === "STOP_LIMIT_CONDITION"
                ? this.$t("exchange.stopLimit")
                : params.row.type === "LIMIT_PRICE" &&
                  params.row.source === "MANUAL_ORDER"
                ? this.$t("exchange.limitOrder")
                : params.row.type === "MARKET_PRICE" &&
                  params.row.source === "MANUAL_ORDER"
                ? this.$t("exchange.marketOrder")
                : params.row.source === "OCO_ORDER"
                ? this.$t("exchange.ocoLimit")
                : params.row.source === "OCO_STOP_LIMIT_ORDER" ||
                  params.row.source === "OCO_CONDITION"
                ? this.$t("exchange.ocoStopLimit")
                : "--"
            );
          },
        },
        {
          title: this.$t("exchange.direction"),
          key: "direction",
          minWidth: 120,
          align: "center",
          render: (h, params) => {
            const row = params.row;
            const className = row.direction.toLowerCase();
            return h(
              "span",
              {
                attrs: {
                  class: className,
                },
              },
              row.direction == "BUY"
                ? this.$t("exchange.buy")
                : this.$t("exchange.sell")
            );
          },
        },
        {
          title: this.$t("exchange.price"),
          sortable: true,
          minWidth: 130,
          align: "center",
          key: "price",
          render: (h, params) => {
            return h(
              "span",
              this.numberWithCommas(
                params.row.price,
                this.getPairCoinScales[params.row.symbol].frontBaseCoinScale
              )
            );
          },
        },
        {
          title: this.$t("exchange.num"),
          sortable: true,
          key: "amount",
          minWidth: 140,
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                params.row.amount.toFixed(
                  this.getPairCoinScales[params.row.symbol].frontCoinScale
                )
              ),
              h(
                "span",
                {
                  style: {
                    fontSize: "9px",
                    marginLeft: "3px",
                  },
                },
                params.row.type === "MARKET_PRICE"
                  ? params.row.direction === "BUY"
                    ? params.row.baseSymbol
                    : params.row.coinSymbol
                  : params.row.coinSymbol
              ),
            ]);
          },
        },
        {
          title: this.$t("exchange.traded"),
          sortable: true,
          key: "tradedAmount",
          minWidth: 150,
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                params.row.tradedAmount.toFixed(
                  this.getPairCoinScales[params.row.symbol].frontCoinScale
                )
              ),
              h(
                "span",
                {
                  style: {
                    fontSize: "9px",
                    marginLeft: "3px",
                  },
                },
                params.row.coinSymbol
              ),
            ]);
          },
        },
        {
          title: this.$t("exchange.percent"),
          minWidth: 140,
          align: "center",
          render: (h, params) => {
            return h("span", params.row.tradedPercentage + "%");
          },
        },
        {
          title: this.$t("exchange.averageTradePrice"),
          key: "averagePrice",
          minWidth: 160,
          align: "center",
          render: (h, params) => {
            return h(
              "span",
              this.numberWithCommas(
                params.row.averagePrice,
                this.getPairCoinScales[params.row.symbol].frontBaseCoinScale
              )
            );
          },
        },
        {
          title: this.$t("exchange.dealAmount"),
          sortable: true,
          key: "turnover",
          minWidth: 140,
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                params.row.turnover.toFixed(
                  this.getPairCoinScales[params.row.symbol].frontBaseCoinScale
                )
              ),
              h(
                "span",
                {
                  style: {
                    fontSize: "9px",
                    marginLeft: "3px",
                  },
                },
                params.row.baseSymbol
              ),
            ]);
          },
        },
        {
          title: this.$t("exchange.refCode"),
          key: "trackingCode",
          minWidth: 200,
          align: "center",
          render: (h, params) => {
            return h("div", params.row.trackingCode);
          },
        },
        {
          title: this.$t("exchange.action"),
          key: "operation",
          slot: "operation",
          minWidth: 120,
          align: "center"
          // render: (h, params) => {
          //   let actions = [];
          //   actions.push(
          //     h(
          //       "Button",
          //       {
          //         props: {
          //           size: "small",
          //         },
          //         style: {
          //           fontSize: "12px",
          //           border: "1px solid #f15057",
          //           color: "#f15057",
          //           padding: "3px 10px",
          //           backgroundColor: "transparent",
          //           borderRadius: "0",
          //           marginLeft: "8px",
          //         },
          //         on: {
          //           click: () => {
          //             this.cancelOrder = true;
          //             this.cancelOrderId = params.index;
          //             // this.cancel(params.index);
          //           },
          //         },
          //       },
          //       this.$t("exchange.undo")
          //     )
          //   );
          //   actions.push(
          //     h(
          //       "Button",
          //       {
          //         props: {
          //           size: "small",
          //         },
          //         style: {
          //           fontSize: "12px",
          //           border: "1px solid #f0ac19",
          //           color: "#f1ac19",
          //           padding: "3px 10px",
          //           backgroundColor: "transparent",
          //           borderRadius: "0",
          //         },
          //         on: {
          //           click: () => {
          //             if (
          //               params.row.source === "MANUAL_ORDER" ||
          //               params.row.source === "OCO_ORDER"
          //             ) {
          //               this.getOrderDetails(params.row.orderId);
          //             } else if (
          //               params.row.source === "STOP_LIMIT_ORDER" ||
          //               params.row.source === "OCO_STOP_LIMIT_ORDER"
          //             ) {
          //               this.getStopLimitOrderDetail({
          //                 id: params.row.orderId,
          //               });
          //             }
          //           },
          //         },
          //       },
          //       this.$t("exchange.more")
          //     )
          //   );
          //   return h("div", actions);
          // },
        },
      ],
      ordersHistoryColumns: [
        {
          type: "index",
          minWidth: 40,
          render: (h, params) => {
            return h(expandRow, {
              props: {
                skin: params.row.skin,
                rows: params.row.detail,
              },
            });
          },
        },
        {
          title: this.$t("exchange.time"),
          sortable: true,
          minWidth: 200,
          align: "center",
          key: "time",
          render: (h, params) => {
            return h(
              "span",
              {},
              this.$options.filters.dateFormat(params.row.time)
            );
          },
        },
        {
          title: this.$t("exchange.symbol"),
          key: "symbol",
          minWidth: 120,
        },
        {
          title: this.$t("exchange.orderType"),
          minWidth: 120,
          align: "center",
          key: "type",
          render: (h, params) => {
            return h(
              "span",
              params.row.type === "MARKET_PRICE" &&
                params.row.source === "MANUAL_ORDER"
                ? this.$t("exchange.marketOrder")
                : params.row.type === "LIMIT_PRICE" &&
                  params.row.source === "MANUAL_ORDER"
                ? this.$t("exchange.limit")
                : params.row.source === "STOP_LIMIT_ORDER" ||
                  params.row.source === "STOP_LIMIT_CONDITION"
                ? this.$t("exchange.stopLimit")
                : params.row.source === "OCO_ORDER"
                ? this.$t("exchange.ocoLimit")
                : params.row.source === "OCO_STOP_LIMIT_ORDER" ||
                  params.row.source === "OCO_CONDITION"
                ? this.$t("exchange.ocoStopLimit")
                : "--"
            );
          },
        },
        {
          title: this.$t("exchange.direction"),
          key: "direction",
          minWidth: 150,
          align: "center",
          render: (h, params) => {
            const row = params.row;
            const className = row.direction.toLowerCase();
            return h(
              "span",
              {
                attrs: {
                  class: className,
                },
              },
              row.direction == "BUY"
                ? this.$t("exchange.buy")
                : this.$t("exchange.sell")
            );
          },
        },
        {
          title: this.$t("exchange.price"),
          sortable: true,
          minWidth: 160,
          align: "center",
          key: "price",
          sortMethod: (a, b, type) => {
            if (type === "asc") {
              return this.compare(a, b);
            } else if (type === "desc") {
              return this.compare(b, a);
            }
          },
          render: (h, params) => {
            return h(
              "span",
              {},
              !isNaN(params.row.price)
                ? this.numberWithCommas(
                    params.row.price,
                    this.getPairCoinScales[params.row.symbol].frontBaseCoinScale
                  )
                : params.row.price
            );
          },
        },
        {
          title: this.$t("exchange.num"),
          sortable: true,
          key: "amount",
          minWidth: 160,
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                params.row.amount.toFixed(
                  this.getPairCoinScales[params.row.symbol].frontCoinScale
                )
              ),
              h(
                "span",
                {
                  style: {
                    fontSize: "9px",
                    marginLeft: "3px",
                  },
                },
                params.row.type === "MARKET_PRICE"
                  ? params.row.direction === "BUY"
                    ? params.row.baseSymbol
                    : params.row.coinSymbol
                  : params.row.coinSymbol
              ),
            ]);
          },
        },
        {
          title: this.$t("exchange.done"),
          sortable: true,
          key: "tradedAmount",
          minWidth: 160,
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                params.row.tradedAmount.toFixed(
                  this.getPairCoinScales[params.row.symbol].frontCoinScale
                )
              ),
              h(
                "span",
                {
                  style: {
                    fontSize: "9px",
                    marginLeft: "3px",
                  },
                },
                params.row.coinSymbol
              ),
            ]);
          },
        },
        {
          title: this.$t("exchange.averageTradePrice"),
          key: "averagePrice",
          sortable: true,
          minWidth: 200,
          align: "center",
          render: (h, params) => {
            return h(
              "span",
              {},
              this.numberWithCommas(
                params.row.averagePrice,
                this.getPairCoinScales[params.row.symbol].frontBaseCoinScale
              )
            );
          },
        },
        {
          title: this.$t("exchange.dealAmount"),
          sortable: true,
          key: "turnover",
          minWidth: 160,
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                params.row.turnover.toFixed(
                  this.getPairCoinScales[params.row.symbol].frontBaseCoinScale
                )
              ),
              h(
                "span",
                {
                  style: {
                    fontSize: "9px",
                    marginLeft: "3px",
                  },
                },
                params.row.baseSymbol
              ),
            ]);
          },
        },
        {
          title: this.$t("exchange.refCode"),
          key: "trackingCode",
          minWidth: 200,
          align: "center",
          render: (h, params) => {
            return h("div", params.row.trackingCode);
          },
        },
        {
          title: this.$t("exchange.status"),
          key: "status",
          minWidth: 160,
          align: "center",
          render: (h, params) => {
            const status = params.row.status;
            if (status == "COMPLETED") {
              return h(
                "span",
                {
                  style: {
                    color: "#f0a70a",
                  },
                },
                this.$t("exchange.finished")
              );
            } else if (status == "ORDERED") {
              return h(
                "span",
                {
                  style: {
                    color: "#f0a70a",
                  },
                },
                this.$t("exchange.ordered")
              );
            } else {
              return h(
                "span",
                {
                  style: {
                    color: "#f15057",
                  },
                },
                this.$t("exchange.canceled")
              );
            }
          },
        },
      ],
      preconditionsHistoryColumns: [
        {
          type: "index",
          minWidth: 40,
          render: (h, params) => {
            return h(expandRow, {
              props: {
                skin: params.row.skin,
                rows: params.row.detail,
              },
            });
          },
        },
        {
          title: this.$t("exchange.time"),
          sortable: true,
          minWidth: 200,
          align: "center",
          key: "createdDate",
          render: (h, params) => {
            return h(
              "span",
              {},
              this.$options.filters.dateFormat(params.row.createdDate)
            );
          },
        },
        {
          title: this.$t("exchange.symbol"),
          key: "symbol",
          minWidth: 120,
          align: "center",
        },
        {
          title: this.$t("exchange.orderType"),
          minWidth: 120,
          align: "center",
          key: "type",
          render: (h, params) => {
            return h(
              "span",
              params.row.source === "STOP_LIMIT_CONDITION"
                ? this.$t("exchange.stopLimit")
                : this.$t("exchange.ocoStopLimit")
            );
          },
        },
        {
          title: this.$t("exchange.direction"),
          key: "direction",
          minWidth: 120,
          align: "center",
          render: (h, params) => {
            const row = params.row;
            const className = row.direction.toLowerCase();
            return h(
              "span",
              {
                attrs: {
                  class: className,
                },
              },
              row.direction == "BUY"
                ? this.$t("exchange.buy")
                : this.$t("exchange.sell")
            );
          },
        },
        {
          title: this.$t("exchange.stopPrice"),
          sortable: true,
          minWidth: 130,
          align: "center",
          key: "activationPrice",
          slot: "activationPrice",
          // render: (h, params) => {
          //   return h(
          //     "span",
          //     params.row.momentMarketPrice >= params.row.activationPrice
          //       ? this.numberWithCommas(
          //           params.row.activationPrice,
          //           this.getPairCoinScales[params.row.symbol].frontBaseCoinScale
          //         ) +
          //           " =" +
          //           ">"
          //       : params.row.momentMarketPrice <= params.row.activationPrice
          //       ? this.numberWithCommas(
          //           params.row.activationPrice,
          //           this.getPairCoinScales[params.row.symbol].frontBaseCoinScale
          //         ) +
          //         " =" +
          //         "<"
          //       : "--"
          //   );
          // },
        },
        {
          title: this.$t("exchange.price"),
          sortable: true,
          minWidth: 130,
          align: "center",
          key: "orderPrice",
          render: (h, params) => {
            return h(
              "span",
              this.numberWithCommas(
                params.row.orderPrice,
                this.getPairCoinScales[params.row.symbol].frontBaseCoinScale
              )
            );
          },
        },
        {
          title: this.$t("exchange.num"),
          sortable: true,
          key: "amount",
          minWidth: 140,
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                params.row.amount.toFixed(
                  this.getPairCoinScales[params.row.symbol].frontCoinScale
                )
              ),
              h(
                "span",
                {
                  style: {
                    fontSize: "9px",
                    marginLeft: "3px",
                  },
                },
                params.row.type === "MARKET_PRICE"
                  ? params.row.direction === "BUY"
                    ? params.row.symbol.split("/")[1]
                    : params.row.symbol.split("/")[0]
                  : params.row.symbol.split("/")[0]
              ),
            ]);
          },
        },
        {
          title: this.$t("exchange.refCode"),
          key: "trackingCode",
          minWidth: 200,
          align: "center",
          render: (h, params) => {
            return h("div", params.row.trackingCode);
          },
        },
        {
          title: this.$t("exchange.status"),
          key: "status",
          minWidth: 160,
          align: "center",
          render: (h, params) => {
            const status = params.row.status;
            if (status == "COMPLETED") {
              return h(
                "span",
                {
                  style: {
                    color: "#f0a70a",
                  },
                },
                this.$t("exchange.finished")
              );
            } else if (status == "ORDERED") {
              return h(
                "span",
                {
                  style: {
                    color: "#f0a70a",
                  },
                },
                this.$t("exchange.ordered")
              );
            } else {
              return h(
                "span",
                {
                  style: {
                    color: "#f15057",
                  },
                },
                this.$t("exchange.canceled")
              );
            }
            // else {
            //     return h("span", {}, "--");
            // }
          },
        },
      ],
      orderDetailColumns: [
        {
          title: this.$t("exchange.num"),
          key: "amount",
          align: "center",
          minWidth: 100,
        },
        {
          title: this.$t("exchange.price"),
          key: "price",
          align: "center",
          minWidth: 100,
          render: (h, params) => {
            return h("span", {}, this.numberWithCommas(params.row.price));
          },
        },
        {
          title: this.$t("exchange.time"),
          key: "time",
          align: "center",
          minWidth: 150,
          render: (h, params) => {
            return h(
              "span",
              {},
              this.$options.filters.dateFormat(params.row.time)
            );
          },
        },
        {
          title: this.$t("exchange.turnover"),
          key: "turnover",
          align: "center",
          minWidth: 120,
        },
      ],
      stopLimitOrdersColumns: [
        {
          type: "index",
          minWidth: 40,
          render: (h, params) => {
            return h(expandRow, {
              props: {
                skin: params.row.skin,
                rows: params.row.detail,
              },
            });
          },
        },
        {
          title: this.$t("exchange.time"),
          sortable: true,
          minWidth: 200,
          align: "center",
          key: "createdDate",
          render: (h, params) => {
            return h(
              "span",
              {},
              this.$options.filters.dateFormat(params.row.createdDate)
            );
          },
        },
        {
          title: this.$t("exchange.symbol"),
          key: "symbol",
          minWidth: 120,
          align: "center",
        },
        {
          title: this.$t("exchange.orderType"),
          minWidth: 140,
          align: "center",
          render: (h, params) => {
            return h(
              "span",
              params.row.source === "STOP_LIMIT_CONDITION"
                ? this.$t("exchange.stopLimit")
                : params.row.source === "OCO_CONDITION"
                ? this.$t("exchange.ocoStopLimit")
                : "--"
            );
          },
        },
        {
          title: this.$t("exchange.direction"),
          key: "direction",
          minWidth: 120,
          align: "center",
          render: (h, params) => {
            const row = params.row;
            const className = row.direction.toLowerCase();
            return h(
              "span",
              {
                attrs: {
                  class: className,
                },
              },
              row.direction == "BUY"
                ? this.$t("exchange.buy")
                : this.$t("exchange.sell")
            );
          },
        },
        {
          title: this.$t("exchange.stopPrice"),
          sortable: true,
          minWidth: 130,
          align: "center",
          key: "activationPrice",
          slot: "activationPrice",
          // render: (h, params) => {
          //   return h(
          //     "span",
          //     params.row.momentMarketPrice >= params.row.activationPrice
          //       ? this.numberWithCommas(
          //           params.row.activationPrice,
          //           this.getPairCoinScales[params.row.symbol].frontBaseCoinScale
          //         ) +
          //           " =" +
          //           ">"
          //       : params.row.momentMarketPrice <= params.row.activationPrice
          //       ? this.numberWithCommas(
          //           params.row.activationPrice,
          //           this.getPairCoinScales[params.row.symbol].frontBaseCoinScale
          //         ) +
          //         " =" +
          //         "<"
          //       : "--"
          //   );
          // },
        },
        {
          title: this.$t("exchange.price"),
          sortable: true,
          minWidth: 130,
          align: "center",
          key: "orderPrice",
          render: (h, params) => {
            return h(
              "span",
              this.numberWithCommas(
                params.row.orderPrice,
                this.getPairCoinScales[params.row.symbol].frontBaseCoinScale
              )
            );
          },
        },
        {
          title: this.$t("exchange.num"),
          sortable: true,
          key: "amount",
          minWidth: 140,
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                this.toFloor(
                  params.row.amount.toFixed(
                    this.getPairCoinScales[params.row.symbol].frontCoinScale
                  )
                )
              ),
              h(
                "span",
                {
                  style: {
                    fontSize: "9px",
                    marginLeft: "3px",
                  },
                },
                params.row.type === "MARKET_PRICE"
                  ? params.row.direction === "BUY"
                    ? params.row.baseSymbol
                    : params.row.coinSymbol
                  : params.row.coinSymbol
              ),
            ]);
          },
        },
        {
          title: this.$t("exchange.refCode"),
          key: "trackingCode",
          align: "center",
          minWidth: 200,
          render: (h, params) => {
            return h("div", params.row.trackingCode);
          },
        },
        {
          title: this.$t("exchange.action"),
          key: "operate",
          minWidth: 120,
          align: "center",
          render: (h, params) => {
            let actions = [];
            actions.push(
              h(
                "Button",
                {
                  props: {
                    size: "small",
                  },
                  style: {
                    fontSize: "12px",
                    border: "1px solid #f15057",
                    color: "#f15057",
                    padding: "3px 10px",
                    backgroundColor: "transparent",
                    borderRadius: "0",
                  },
                  on: {
                    click: () => {
                      this.isPreconditionOrderCanceled = true;
                      this.PreconditionOrderCanceledId = params.index;
                      // this.cancel(params.index);
                    },
                  },
                },
                this.$t("exchange.undo")
              )
            );
            return h("div", actions);
          },
        },
      ],
      orderStopLimitDetailsColumns: [
        {
          title: this.$t("exchange.num"),
          key: "amount",
          align: "center",
        },
        {
          title: this.$t("exchange.price"),
          key: "price",
          align: "center",
          render: (h, params) => {
            return h("span", {}, this.numberWithCommas(params.row.price));
          },
        },
        {
          title: this.$t("exchange.time"),
          key: "time",
          align: "center",
          sortable: true,
          width: 180,
          render: (h, params) => {
            return h("span", {}, this.$options.filters.dateFormat(params.row.time));
          },
        },
        {
          title: this.$t("exchange.turnover"),
          key: "turnover",
          align: "center",
        },
      ],
      preconditionDetailsColumns: [
        {
          title: this.$t("exchange.num"),
          key: "amount",
          align: "center",
        },
        {
          title: this.$t("exchange.price"),
          key: "price",
          align: "center",
          render: (h, params) => {
            return h("span", {}, this.numberWithCommas(params.row.price));
          },
        },
        {
          title: this.$t("exchange.time"),
          key: "time",
          align: "center",
          sortable: true,
          width: 180,
          render: (h, params) => {
            return h("span", {}, this.$options.filters.dateFormat(params.row.time));
          },
        },
        {
          title: this.$t("exchange.turnover"),
          key: "turnover",
          align: "center",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getPairCoinScales: "market/GET_PAIR_COIN_SCALES",
    }),
  },
  mounted() {},
  methods: {
    ...mapActions({
      cancelPreconditionOrderService:
        "exchange/CANCEL_PRECONDITION_ORDER_SERVICE",
      orderCancel: "exchange/CANCEL_ORDER_SERVICE",
      getPreconditionOrderDetailByOrderID:
        "exchange/GET_PRECONDITION_ORDER_DETAIL_BY_ORDER_ID_SERVICE",
      getExchangeOrderDetail: "exchange/GET_EXCHANGE_ORDER_DETAIL_SERVICE",
    }),
    cancelOrder(orderId) {
      if (this.detailsBtnLoading !== null) return;
      this.cancelOrderModal = true;
      this.cancelOrderId = orderId;
    },
    getDetails(data) {
      if (this.detailsBtnLoading !== null) return;
      if (
          data.source === "MANUAL_ORDER" ||
          data.source === "OCO_ORDER"
      ) {
        this.getOrderDetails(data.orderId);
      } else if (
          data.source === "STOP_LIMIT_ORDER" ||
          data.source === "OCO_STOP_LIMIT_ORDER"
      ) {
        this.getStopLimitOrderDetail(data.orderId);
      }
    },
    changeOrder(str) {
      if (this.detailsBtnLoading === null)
        this.selectedOrder = str;
    },
    cancelPreconditionOrder(index) {
      let order = this.stopOrdersTableData[index];
      this.cancelPreconditionOrderService(order.id)
        .then(() => {
          this.$emit("onCancelOrder");
          this.$toast.success(this.$t("exchange.successCancel"));
          this.PreconditionOrderCanceledId = "";
          this.isPreconditionOrderCanceled = false;
        })
        .catch((err) => {
          this.$toast.error(err.message);
        });
    },
    cancel() {
      // let order = this.openOrdersTableData[index];
      this.orderCancel(this.cancelOrderId)
        .then(() => {
          this.$emit("onCancelOrder");
          this.$toast.success(this.$t("exchange.successCancel"));
          this.cancelOrderId = "";
          this.cancelOrderModal = false;
        })
        .catch((err) => {
          this.$toast.error(err.message);
        });
    },
    getStopLimitOrderDetail(orderId) {
      this.detailsBtnLoading = orderId;
      this.getPreconditionOrderDetailByOrderID({id: orderId}).then((response) => {
        this.orderStopLimitDetailsData = response.data.data;
        this.isOrderStopLimitModalOpen = true;
      }).finally(() => {
        this.detailsBtnLoading = null;
      })
    },
    getOrderDetails(orderId) {
      this.detailsBtnLoading = orderId;
      this.getExchangeOrderDetail(orderId).then((response) => {
        this.orderDetailsData = response.data.data.detail;
        this.isOrderDetailModalOpen = true;
      }).finally(() => {
        this.detailsBtnLoading = null;
      })
    },
    closeOrderDetailModal() {
      this.isOrderDetailModalOpen = false;
    },
  },
};
