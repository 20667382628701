import { mapActions } from "vuex";
import { DataFeed, widget as TvWidget } from "tradingview-api";
var Stomp = require("stompjs");
var SockJS = require("sockjs-client");

const supported_resolutions = [
  "1",
  "5",
  "15",
  "30",
  "60",
  "240",
  "1D",
  "1W",
  "1M",
];
const intervalMap = {
  "1min": "1",
  "5min": "5",
  "15min": "15",
  "30min": "30",
  "1hour": "60",
  // '60min': '60',
  "4hour": "240",
  "1day": "1D",
  "1week": "1W",
  "1month": "1M",
  // '1mon': '1M'
};

export default {
  name: "local-tradingview",
  props: {
    symbol: {
      type: String,
    },
    stompSocket: {
      type: Object,
    },
    coinScale: {
      type: Number,
    },
    loading: {
        type: Boolean
    }
  },
  data() {
    return {
      subscribtion: {},
      widget: null,
      interval: "5min",
      datafeed: new DataFeed({
        getBars: (params) => this.getBars(params),
        fetchResolveSymbol: () => this.resolveSymbol(),
        fetchConfiguration: () => {
          return new Promise((resolve) => {
            resolve({
              supported_resolutions,
            });
          });
        },
      }),
    };
  },
  computed: {},
  mounted() {
    this.getKline();
  },
  methods: {
    ...mapActions({
      getKLineData: "tradingView/GET_KLINES_DATA",
    }),
    resolveSymbol() {
      return new Promise((resolve) => {
        const symbol = this.symbol;
        resolve({
          name: symbol.toLocaleUpperCase(),
          full_name: symbol.toLocaleUpperCase(),
          description: symbol.toLocaleUpperCase(),
          type: symbol,
          session: "24x7",
          exchange: "Excoino",
          pricescale: Math.pow(10, this.coinScale),
          listed_exchange: symbol,
          timezone: "Asia/Tehran",
          format: "price",
          minmov: 1,
          // volume_precision: info['value-precision'],
          has_intraday: true,
          supported_resolutions,
          has_weekly_and_monthly: true,
        });
      });
    },
    async getBars(params) {
      const symbol = this.symbol;
      const size = window.innerWidth;
      if (
        !params.firstDataRequest /** Whether to request historical data for the first time */
      ) {
        // Huobi API temporarily does not support segmented query historical data
        return {
          bars: [],
          meta: {
            noData: true,
          },
        };
      }
      if (params.resolution !== intervalMap[this.interval]) {
        this.unsubscribeKLine();
        for (const key in intervalMap) {
          if (intervalMap[key] === params.resolution) {
            this.interval = key;
          }
        }
      }
      const res = await this.getKLineData({
        symbol,
        from: params.from * 1000,
        to: params.firstDataRequest ? new Date().getTime() : params.to * 1000,
        resolution: intervalMap[this.interval],
        size: size > 2000 ? 2000 : size,
      });
      if (
        params.resolution === intervalMap[this.interval] &&
        params.firstDataRequest &&
        res.data &&
        res.data.length
      ) {
        this.subscribeKLine(this.interval);
      }

      document.getElementById("chart_wrapper").classList.add("d-block");
      this.$emit("chartsLoading");

      if (!res.data || !res.data.length) {
        return {
          bars: [],
          meta: { noData: true },
        };
      }
      const list = [];
      for (let i = 0; i < res.data.length; i++) {
        const item = res.data[i];
        if (params.resolution === "1D") {
          list.push({
            time: item[0] + 12600000,
            open: item[1],
            high: item[2],
            low: item[3],
            close: item[4],
            volume: item[5],
          });
        } else if (params.resolution === "1W") {
          list.push({
            time: item[0] + 99000000,
            open: item[1],
            high: item[2],
            low: item[3],
            close: item[4],
            volume: item[5],
          });
        } else if (params.resolution === "1M") {
          list.push({
            time: item[0] + 16200000,
            open: item[1],
            high: item[2],
            low: item[3],
            close: item[4],
            volume: item[5],
          });
        } else {
          list.push({
            time: item[0],
            open: item[1],
            high: item[2],
            low: item[3],
            close: item[4],
            volume: item[5],
          });
        }
      }
      list.sort((l, r) => (l.time > r.time ? 1 : -1));
      return {
        bars: list,
        meta: {
          noData: !list.length,
        },
      };
    },
    getKline() {
      let config = {
        autosize: true,
        height: 320,
        fullscreen: true,
        theme: "dark",
        studies_overrides: {
          "volume.show ma": true,
        },
        disabled_features: [
          "header_compare"
        ],
        symbol: this.symbol.toLocaleUpperCase(),
        interval: intervalMap[this.interval], // default candlestick period
        timezone: "Asia/Tehran",
        // toolbar_bg: "#18202a",
        container_id: "tv_chart_container",
        datafeed: this.datafeed,
        library_path: "/charting_library/",
        locale: "en",
        debug: false,
      };

      this.widget = new TvWidget(config);
    },
    subscribeKLine(resolution) {
      // const symbol = this.symbol

      // if (this.stompClient) {
      //   this.stompClient.ws.close();
      // }
      // var stompClient = null;
      // let scale = that.baseCoinScale;
      // console.log(new SockJS("wss://api-fa-market-dev.ernyka.com/market/market-ws/websocket"));
      // var socket = new SockJS("wss://api-fa-market-dev.ernyka.com/market/market-ws/websocket");

      // stompClient = Stomp.over(socket);
      // this.stompClient = stompClient;
      // stompClient.debug = false;
      // stompClient.connect({}, (frame) =>
      this.subscribtion = this.stompSocket.subscribe(
        "/topic/market/kline/" + this.symbol + "/" + resolution,
        (msg) => {
          // if (resolution != "1") return;
          // if (that.currentBar != null) onRealtimeCallback(that.currentBar);
          var resp = JSON.parse(msg.body);
          if (resolution === "1day") {
            this.datafeed.updateKLine({
              time: resp.time + 12600000,
              open: resp.openPrice,
              high: resp.highestPrice,
              low: resp.lowestPrice,
              close: resp.closePrice,
              volume: resp.volume,
            });
          } else if (resolution === "1week") {
            this.datafeed.updateKLine({
              time: resp.time + 99000000,
              open: resp.openPrice,
              high: resp.highestPrice,
              low: resp.lowestPrice,
              close: resp.closePrice,
              volume: resp.volume,
            });
          } else if (resolution === "1month") {
            this.datafeed.updateKLine({
              time: resp.time + 16200000,
              open: resp.openPrice,
              high: resp.highestPrice,
              low: resp.lowestPrice,
              close: resp.closePrice,
              volume: resp.volume,
            });
          } else {
            this.datafeed.updateKLine({
              time: resp.time,
              open: resp.openPrice,
              high: resp.highestPrice,
              low: resp.lowestPrice,
              close: resp.closePrice,
              volume: resp.volume,
            });
          }
          // that.lastBar = {time:resp.time,open:resp.openPrice,high:resp.highestPrice,low:resp.lowestPrice,close:resp.closePrice,volume:resp.volume};
          // that.currentBar = that.lastBar;
          // onRealtimeCallback(that.lastBar);
          // that.lastBar.newKlineStatus = true;
        }
      );
      // });
      // ws.subscribe(
      //     `market.${symbol}.kline.${this.interval}`,
      //     {
      //       id: 'react-tv',
      //       sub: `market.${symbol}.kline.${this.interval}`
      //     },
      //     (data) => {
      //       const tick = data.tick
      //       this.datafeed.updateKLine({
      //         time: tick.id * 1000,
      //         open: tick.open,
      //         high: tick.high,
      //         low: tick.low,
      //         close: tick.close,
      //         volume: tick.vol
      //       })
      //     }
      // )
    },
    unsubscribeKLine() {
      this.subscribtion.unsubscribe();
      // const symbol = this.symbol
      // ws.unsubscribe(`market.${symbol}.kline.${this.interval}`)
    },
  },
};
