import { render, staticRenderFns } from "./Tradingview.html?vue&type=template&id=b522d666&scoped=true&"
import script from "./Tradingview.js?vue&type=script&lang=js&"
export * from "./Tradingview.js?vue&type=script&lang=js&"
import style0 from "./tradingview.scss?vue&type=style&index=0&id=b522d666&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b522d666",
  null
  
)

export default component.exports