import Loader from "@/components/UI/LoadingComp"

export default {
  name: "market-table",
  components: {
    Loader
  },
  props: {
    dataTable: {
      type: Array,
    },
    tableHeader: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
    chosenBtn: {
      type: Number,
    },
    selectedBaseCoin: {
      type: String,
    },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {
    goHref(val) {
      this.$emit("goHref", val);
    },
  },
};
