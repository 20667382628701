import Vue from "vue";
import HighchartsVue from "highcharts-vue";
import Highcharts from "highcharts";
import noData from "highcharts/modules/no-data-to-display";

noData(Highcharts);
Vue.use(HighchartsVue);

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

export default {
  name: "HighChart",
  props: {
    trades: {
      type: Object,
      default: () => {},
    },
    coinScale: {
      type: Number,
      default: 0,
    },
    baseCoinScale: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      options: {
        chart: {
          type: "area",
          height: "360",
          backgroundColor: "#192330",
          zoomType: "xy",
          panning: true,
          panKey: "shift",
          style: {
            fontFamily: "webIRANSansRegular, sans-serif",
            fontWeight: "normal"
          },
          resetZoomButton: {
            theme: {
              width: 60,
              height: 8,
              fill: "white",
              stroke: "#f1ac1c",
              r: 4, // borer-radius
              states: {
                hover: {
                  fill: "#f1ac1c",
                  style: {
                    color: "white",
                  },
                },
              },
            },
          },
        },
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 1100,
              },
              // chartOptions: {
              //     legend: {
              //         enabled: false
              //     }
              // }
            },
          ],
        },
        credits: {
          enabled: false,
        },
        title: false,
        tooltip: {
          useHTML: true,
          formatter: function () {},
          shared: false,
          borderRadius: 4,
          followPointer: true,
          followTouchMove: true,
        },
        lang: {
          noData: this.$t("exchange.noOrders"),
        },
        noData: {
          style: {
            fontWeight: "normal",
            fontSize: "16px",
            color: "#fff",
          },
        },
        legend: {
          itemStyle: {
            color: "white",
          },
        },
        xAxis: [
          {
            labels: {
              rotation: -45,
              style: {
                color: "white",
              },
            },
            reversed: false,
            width: "50%",
            minPadding: 0,
            maxPadding: 0,
          },
          {
            labels: {
              rotation: -45,
              style: {
                color: "white",
              },
            },
            offset: 0,
            left: "50%",
            width: "50%",
            minPadding: 0,
            maxPadding: 0,
          },
        ],
        yAxis: {
          labels: {
            style: {
              color: "white",
            },
          },
          title: {
            text: this.$t("exchange.total"),
            style: {
              color: "white",
            },
          },
          gridLineColor: "gray",
          gridLineDashStyle: "longdash", // Solid, ShortDash, LongDashDot
          // gridLineWidth: 0,
        },
        // plotOptions: {
        //     series: {
        //         // allowPointSelect: true,
        //         marker: {
        //             enabled: true // show all markers
        //         }
        //     }
        // },
        plotOptions: {
          area: {
            stacking: "normal",
          },
        },
        series: [
          {
            name: this.$t("exchange.buy"),
            data: [],
            color: "#3ab275",
            xAxis: 0,
          },
          {
            name: this.$t("exchange.sell"),
            data: [],
            color: "#f15057",
            xAxis: 1,
          },
        ],
      },
    };
  },
  computed: {
    asks() {
      for (let i = 1; i < this.trades.ask.items.length; i++) {
        this.trades.ask.items[i].amount += this.trades.ask.items[i - 1].amount;
      }
      return this.trades.ask.items.map((elem) => {
        return [elem.price, elem.amount];
      });
    },
    bids() {
      for (let i = 1; i < this.trades.bid.items.length; i++) {
        this.trades.bid.items[i].amount += this.trades.bid.items[i - 1].amount;
      }
      return this.trades.bid.items.map((elem) => {
        return [elem.price, elem.amount];
      });
    },
    chartOptions() {
      const price = this.$t("exchange.price")
      const total = this.$t("exchange.total")
      const baseCoinScale = this.baseCoinScale;
      const coinScale = this.coinScale;
      this.options.series[0].data = this.bids;
      this.options.series[1].data = this.asks;
      this.options.tooltip.formatter = function () {
        return `<div style="text-align: right;">
                            ${price}:
                            ${this.point.x.toFixed(baseCoinScale)}<br/>
                            ${total} ${
                             this.point.series.name
                           }: ${this.point.y.toFixed(coinScale)}
                        </div>`;
      };
      return this.options;
    },
  },
  beforeUpdate() {
    document.getElementById("depth_chart").classList.add("d-block");
  }
};
