<template>
  <Row type="flex" :gutter="30">
    <Col
      v-if="orderType === 'both' || orderType === 'buy'"
      :xs="{ span: 24 }"
      :lg="{ span: 12 }"
    >
      <Form ref="buyForm" :model="buy">
        <Row
          type="flex"
          class="justify-start align-center font-Regular-12 mb-2"
        >
          <span class="me-2">{{ $t("exchange.limited_price") }}</span>
        </Row>
        <FormItem prop="ocoLimitPrice">
          <label class="before" :style="inputLabelStyle">{{ $t("exchange.limitOrderPrice") }}</label>
          <Input
            @on-keyup="handleLimitPrice('buy')"
            v-model="buy.ocoLimitPrice"
            placeholder="0"
          >
            <span slot="prepend">
              {{ currentCoin.base }}
            </span>
          </Input>
          <!--          <label class="after">{{ currentCoin.base }}</label>-->
        </FormItem>
        <Row
          type="flex"
          class="justify-start align-center font-Regular-12 mb-2"
        >
          <span class="me-2">{{ $t("exchange.stop_order_price") }}</span>
        </Row>
        <FormItem prop="ocoActivationPrice">
          <label class="before" :style="inputLabelStyle">{{ $t("exchange.activationPrice") }}</label>
          <Input
            @on-keyup="handleActivationPrice('buy')"
            v-model="buy.ocoActivationPrice"
            placeholder="0"
          >
            <span slot="prepend">
              {{ currentCoin.base }}
            </span>
          </Input>
          <!--          <label class="after">{{ currentCoin.base }}</label>-->
        </FormItem>
        <FormItem prop="ocoConditionalOrderPrice">
          <label class="before" :style="inputLabelStyle">{{ $t("exchange.buyPrice") }}</label>
          <Input
            @on-keyup="handleConditionalPrice('buy')"
            v-model="buy.ocoConditionalOrderPrice"
            placeholder="0"
          >
            <span slot="prepend">
              {{ currentCoin.base }}
            </span>
          </Input>
          <!--          <label class="after">{{ currentCoin.base }}</label>-->
        </FormItem>
        <FormItem prop="ocoAmount">
          <label class="before" :style="inputLabelStyle">{{ $t("exchange.buyAmount") }}</label>
          <Input
            @on-keyup="handleOcoAmount('buy')"
            v-model="buy.ocoAmount"
            placeholder="0"
          >
            <span slot="prepend">
              {{ currentCoin.coin }}
            </span>
          </Input>
          <!--          <label class="after">{{ currentCoin.coin }}</label>-->
        </FormItem>
        <FormItem class="slider-wrap" prop="sliderPercent">
          <Slider
            class="slider-buy"
            v-model="buy.sliderPercent"
            :step="1"
            show-tip="always"
            :tip-format="tipFormat"
            :disabled="buySliderDisabled"
            @on-change="handleBuySlider"
          ></Slider>
          <div
            class="slider-stop"
            :class="{ 'slider-stop-enable': !buySliderDisabled }"
            v-for="item in sliderStep"
            :key="`${item}-buy`"
            :style="'left: ' + item + '%;'"
            @click="updateStepper('buy', item, buySliderDisabled)"
          >
            <div
              class="slider-block"
              :class="{ 'buy-slider-block-active': buy.sliderPercent > item }"
            ></div>
          </div>
        </FormItem>
        <div class="text-start font-Regular-12 text-grey mb-3">
          {{ $t("exchange.amount") }}
          <span>{{
            buy.ocoTurnover | numberWithCommas(toFloor(baseCoinScale))
          }}</span>
          {{ currentCoin.base }}
        </div>
        <Button
          :loading="loadingOcoAskButton"
          :disabled="!enableOco || !isExchangeable || noBaseCoins"
          class="pt-2 pb-2 font-Regular-12 text-white fill-width"
          :class="
            enableOco && isExchangeable && !noBaseCoins ? 'bg-green' : 'bg-gray'
          "
          @click="buyWithOcoPrice"
          >{{ $t("exchange.buy") }} ({{ currentCoin.coin }})
        </Button>
        <!--        <Button v-else class="bg-gray pt-2 pb-2 font-Bold-13 text-white fill-width">-->
        <!--          {{ $t("exchange.buyin") }} ({{ currentCoin.coin }})-->
        <!--        </Button>-->
      </Form>
    </Col>

    <Col
      v-if="orderType === 'both' || orderType === 'sell'"
      :xs="{ span: 24 }"
      :lg="{ span: 12 }"
    >
      <Form ref="sellForm" :model="sell">
        <Row
          type="flex"
          class="justify-start align-center font-Regular-12 mb-2"
        >
          <span class="me-2">{{ $t("exchange.limited_price") }}</span>
        </Row>
        <FormItem prop="ocoLimitPrice">
          <label class="before" :style="inputLabelStyle">{{ $t("exchange.limitOrderPrice") }}</label>
          <Input
            @on-keyup="handleLimitPrice('sell')"
            v-model="sell.ocoLimitPrice"
            placeholder="0"
          >
            <span slot="prepend">
              {{ currentCoin.base }}
            </span>
          </Input>
          <!--          <label class="after">{{ currentCoin.base }}</label>-->
        </FormItem>
        <Row
          type="flex"
          class="justify-start align-center font-Regular-12 mb-2"
        >
          <span class="me-2">{{ $t("exchange.stop_order_price") }}</span>
        </Row>
        <FormItem prop="ocoActivationPrice">
          <label class="before" :style="inputLabelStyle">{{ $t("exchange.activationPrice") }}</label>
          <Input
            @on-keyup="handleActivationPrice('sell')"
            v-model="sell.ocoActivationPrice"
            placeholder="0"
          >
            <span slot="prepend">
              {{ currentCoin.base }}
            </span>
          </Input>
          <!--          <label class="after">{{ currentCoin.base }}</label>-->
        </FormItem>
        <FormItem prop="ocoConditionalOrderPrice">
          <label class="before" :style="inputLabelStyle">{{ $t("exchange.sellPrice") }}</label>
          <Input
            @on-keyup="handleConditionalPrice('sell')"
            v-model="sell.ocoConditionalOrderPrice"
            placeholder="0"
          >
            <span slot="prepend">
              {{ currentCoin.base }}
            </span>
          </Input>
          <!--          <label class="after">{{ currentCoin.base }}</label>-->
        </FormItem>
        <FormItem prop="ocoAmount">
          <label class="before" :style="inputLabelStyle">{{ $t("exchange.sellAmount") }}</label>
          <Input
            @on-keyup="handleOcoAmount('sell')"
            v-model="sell.ocoAmount"
            placeholder="0"
          >
            <span slot="prepend">
              {{ currentCoin.coin }}
            </span>
          </Input>
          <!--          <label class="after">{{ currentCoin.coin }}</label>-->
        </FormItem>
        <FormItem class="slider-wrap" prop="sliderPercent">
          <Slider
            class="silder-sell"
            v-model="sell.sliderPercent"
            :step="1"
            show-tip="always"
            :tip-format="tipFormat"
            :disabled="sellSliderDisabled"
            @on-change="handleSellSlider"
          ></Slider>
          <div
            class="slider-stop"
            :class="{ 'slider-stop-enable': !sellSliderDisabled }"
            v-for="item in sliderStep"
            :key="`${item}-sell`"
            :style="'left: ' + item + '%;'"
            @click="updateStepper('sell', item, sellSliderDisabled)"
          >
            <div
              class="slider-block"
              :class="{ 'sell-slider-block-active': sell.sliderPercent > item }"
            ></div>
          </div>
        </FormItem>
        <div class="text-start font-Regular-12 text-grey mb-3">
          {{ $t("exchange.amount") }}
          <span>{{
            sell.ocoTurnover | numberWithCommas(toFloor(coinScale))
          }}</span>
          {{ currentCoin.base }}
        </div>
        <Button
          :loading="loadingOcoBidButton"
          :disabled="!enableOco || !isExchangeable || noBaseCoins"
          class="pt-2 pb-2 font-Regular-12 text-white fill-width"
          :class="
            enableOco && isExchangeable && !noBaseCoins ? 'bg-red' : 'bg-gray'
          "
          @click="sellWithOcoPrice"
          >{{ $t("exchange.sell") }} ({{ currentCoin.coin }})
        </Button>
        <!--        <Button v-else class="bg-gray pt-2 pb-2 font-Bold-13 text-white fill-width">-->
        <!--          {{ $t("exchange.sellout") }} ({{ currentCoin.coin }})-->
        <!--        </Button>-->
      </Form>
    </Col>
  </Row>
</template>

<script>
import { handleInput, removeThousand } from "@/utiles/numericValidations";
import { mapActions } from "vuex";

export default {
  name: "MarketOrderForm",
  props: {
    orderType: {
      type: String,
      required: true,
    },
    currentCoin: {
      type: Object,
      required: true,
    },
    baseCoinScale: {
      type: Number,
      required: true,
    },
    coinScale: {
      type: Number,
      required: true,
    },
    wallet: {
      type: Object,
      required: true,
    },
    platesPrice: {
      type: String,
    },
    isExchangeable: {
      type: Boolean,
      required: true,
    },
    enableOco: {
      type: Boolean,
      required: true,
    },
    noBaseCoins: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      buy: {
        ocoLimitPrice: "",
        ocoActivationPrice: "",
        ocoConditionalOrderPrice: "",
        ocoAmount: "",
        ocoTurnover: 0.0,
        sliderPercent: 0,
      },
      sell: {
        ocoLimitPrice: "",
        ocoActivationPrice: "",
        ocoConditionalOrderPrice: "",
        ocoAmount: "",
        ocoTurnover: 0.0,
        sliderPercent: 0,
      },
      sliderStep: [25, 50, 75, 100],
      loadingOcoAskButton: false,
      loadingOcoBidButton: false,
    };
  },
  watch: {
    platesPrice(val, oldVal) {
      if (val !== oldVal) {
        this.resetForms();
        this.setPrices();
      }
    },
  },
  computed: {
    sellSliderDisabled() {
      let account = this.wallet.coin;
      let min = this.toFloor(1 / Math.pow(10, this.coinScale));
      return account < min || !this.enableOco || !this.isExchangeable;
    },
    buySliderDisabled() {
      let account = this.wallet.base;
      let min = this.toFloor(1 / Math.pow(10, this.baseCoinScale));
      return account < min || !this.enableOco || !this.isExchangeable;
    },
    inputLabelStyle() {
      return {
        right: this.isLocaleLTR ? '10px' : 'unset',
        left: this.isLocaleLTR ? 'unset' : '10px'
      };
    },
  },
  mounted() {
    this.resetForms();
    this.setPrices();
  },
  methods: {
    ...mapActions({
      addOCOOrder: "exchange/ADD_OCO_ORDER_SERVICE",
    }),
    resetForms() {
      if (this.orderType === "both") {
        this.$refs.buyForm.resetFields();
        this.$refs.sellForm.resetFields();
      } else {
        this.$refs[`${this.orderType}Form`].resetFields();
      }
    },
    setPrices() {
      this.buy.ocoLimitPrice = handleInput(
        this.platesPrice,
        this.baseCoinScale
      );
      this.buy.ocoConditionalOrderPrice = handleInput(
        this.platesPrice,
        this.baseCoinScale
      );
      this.sell.ocoLimitPrice = handleInput(
        this.platesPrice,
        this.baseCoinScale
      );
      this.sell.ocoConditionalOrderPrice = handleInput(
        this.platesPrice,
        this.baseCoinScale
      );
    },
    updateStepper(key, val, disabled) {
      if (!disabled) {
        this[key].sliderPercent = val;
        if (key === "buy") this.handleBuySlider(val);
        else this.handleSellSlider(val);
      }
    },
    tipFormat(val) {
      return val + "%";
    },
    handleLimitPrice(key) {
      this[key].ocoLimitPrice = handleInput(
        this[key].ocoLimitPrice,
        this.baseCoinScale
      );
    },
    handleActivationPrice(key) {
      this[key].ocoActivationPrice = handleInput(
        this[key].ocoActivationPrice,
        this.baseCoinScale
      );
    },
    handleConditionalPrice(key) {
      this[key].ocoConditionalOrderPrice = handleInput(
        this[key].ocoConditionalOrderPrice,
        this.baseCoinScale
      );
      const ocoConditionalOrderPrice = removeThousand(
        this[key].ocoConditionalOrderPrice
      );
      const ocoAmount = removeThousand(this[key].ocoAmount);

      this[key].ocoTurnover = this.toFloor(
        ocoConditionalOrderPrice.mul(ocoAmount)
      );
      this.setSliderPercent(key, ocoConditionalOrderPrice, ocoAmount);
    },
    handleOcoAmount(key) {
      this[key].ocoAmount = handleInput(this[key].ocoAmount, this.coinScale);
      const ocoConditionalOrderPrice = removeThousand(
        this[key].ocoConditionalOrderPrice
      );
      const ocoAmount = removeThousand(this[key].ocoAmount);

      this[key].ocoTurnover = this.toFloor(
        ocoConditionalOrderPrice.mul(ocoAmount)
      );
      this.setSliderPercent(key, ocoConditionalOrderPrice, ocoAmount);
    },
    setSliderPercent(key, ocoConditionalOrderPrice, ocoAmount) {
      let account = this.wallet.coin;
      let turnOver = ocoAmount;
      if (key === "buy") {
        account = this.wallet.base;
        turnOver = ocoConditionalOrderPrice.mul(ocoAmount);
      }
      this[key].sliderPercent =
        account > 0 &&
        parseFloat(ocoConditionalOrderPrice) > 0 &&
        parseFloat(ocoAmount) > 0
          ? parseFloat(turnOver.div(account).mul(100))
          : 0;
    },
    handleBuySlider(val) {
      let price = removeThousand(this.buy.ocoConditionalOrderPrice),
        account = this.wallet.base,
        amount = 0;
      if (price > 0) {
        amount = account.div(price).mul(val).mul(0.01);
      }
      this.buy.ocoAmount = handleInput(amount, this.coinScale);
      this.buy.ocoTurnover = this.toFloor(
        removeThousand(this.buy.ocoAmount).mul(
          removeThousand(this.buy.ocoConditionalOrderPrice)
        )
      );
    },
    handleSellSlider(val) {
      this.sell.ocoAmount = handleInput(
        this.wallet.coin.mul(val).mul(0.01),
        this.coinScale
      );
      this.sell.ocoTurnover = this.toFloor(
        removeThousand(this.sell.ocoAmount).mul(
          removeThousand(this.sell.ocoConditionalOrderPrice)
        )
      );
    },
    buyWithOcoPrice() {
      this.loadingOcoAskButton = true;
      const ocoLimitPrice = removeThousand(this.buy.ocoLimitPrice);
      const ocoActivationPrice = removeThousand(this.buy.ocoActivationPrice);
      const ocoConditionalOrderPrice = removeThousand(
        this.buy.ocoConditionalOrderPrice
      );
      const ocoAmount = removeThousand(this.buy.ocoAmount);

      if (!ocoLimitPrice || ocoLimitPrice === "0") {
        this.$toast.error(this.$t("exchange.enterLimitOrderPrice"));
        this.loadingOcoAskButton = false;
        return;
      }
      if (!ocoActivationPrice || ocoActivationPrice === "0") {
        this.$toast.error(this.$t("exchange.enterActivationPrice"));
        this.loadingOcoAskButton = false;
        return;
      }
      if (!ocoConditionalOrderPrice || ocoConditionalOrderPrice === "0") {
        this.$toast.error(this.$t("exchange.enterBuyPrice"));
        this.loadingOcoAskButton = false;
        return;
      }
      if (!ocoAmount || ocoAmount === "0") {
        this.$toast.error(this.$t("exchange.enterBuyAmount"));
        this.loadingOcoAskButton = false;
        return;
      }
      const maxAmount = this.wallet.base / ocoLimitPrice;
      if (ocoAmount > maxAmount) {
        this.$toast.error(this.$t("exchange.insufficientBalance"));
        this.loadingOcoAskButton = false;
        return;
      }

      let payload = {
        activationPrice: ocoActivationPrice,
        amount: ocoAmount,
        conditionalOrderPrice: ocoConditionalOrderPrice,
        direction: "BUY",
        limitOrderPrice: ocoLimitPrice,
        symbol: this.currentCoin.symbol,
      };
      this.addOCOOrder(payload)
        .then(() => {
          this.$toast.success(this.$t("exchange.success"));
          this.$emit("refresh");
          this.buy.ocoAmount = "";
          this.buy.sliderPercent = 0;
        })
        .catch((err) => {
          this.$toast.error(err.message);
        })
        .finally(() => {
          this.loadingOcoAskButton = false;
        });
    },
    sellWithOcoPrice() {
      this.loadingOcoBidButton = true;
      const ocoLimitPrice = removeThousand(this.sell.ocoLimitPrice);
      const ocoActivationPrice = removeThousand(this.sell.ocoActivationPrice);
      const ocoConditionalOrderPrice = removeThousand(
        this.sell.ocoConditionalOrderPrice
      );
      const ocoAmount = removeThousand(this.sell.ocoAmount);

      if (!ocoLimitPrice || ocoLimitPrice === "0") {
        this.$toast.error(this.$t("exchange.enterLimitOrderPrice"));
        this.loadingOcoBidButton = false;
        return;
      }
      if (!ocoActivationPrice || ocoActivationPrice === "0") {
        this.$toast.error(this.$t("exchange.enterActivationPrice"));
        this.loadingOcoBidButton = false;
        return;
      }
      if (!ocoConditionalOrderPrice || ocoConditionalOrderPrice === "0") {
        this.$toast.error(this.$t("exchange.enterSellPrice"));
        this.loadingOcoBidButton = false;
        return;
      }
      if (!ocoAmount || ocoAmount === "0") {
        this.$toast.error(this.$t("exchange.enterSellAmount"));
        this.loadingOcoBidButton = false;
        return;
      }
      if (ocoAmount > parseFloat(this.wallet.coin)) {
        this.$toast.error(this.$t("exchange.insufficientBalance"));
        this.loadingOcoBidButton = false;
        return;
      }

      let payload = {
        activationPrice: ocoActivationPrice,
        amount: ocoAmount,
        conditionalOrderPrice: ocoConditionalOrderPrice,
        direction: "SELL",
        limitOrderPrice: ocoLimitPrice,
        symbol: this.currentCoin.symbol,
      };
      this.addOCOOrder(payload)
        .then(() => {
          this.$toast.success(this.$t("exchange.success"));
          this.$emit("refresh");
          this.sell.ocoAmount = "";
          this.sell.sliderPercent = 0;
        })
        .catch((err) => {
          this.$toast.error(err.message);
        })
        .finally(() => {
          this.loadingOcoBidButton = false;
        });
    },
  },
};
</script>

<style scoped></style>
