import { render, staticRenderFns } from "./NewDepthGraph.html?vue&type=template&id=f996837a&scoped=true&"
import script from "./NewDepthGraph.js?vue&type=script&lang=js&"
export * from "./NewDepthGraph.js?vue&type=script&lang=js&"
import style0 from "./NewDepthGraph.scss?vue&type=style&index=0&id=f996837a&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f996837a",
  null
  
)

export default component.exports