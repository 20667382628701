import { render, staticRenderFns } from "./Exchange.html?vue&type=template&id=7bd1f7e1&scoped=true&"
import script from "./Exchange.js?vue&type=script&lang=js&"
export * from "./Exchange.js?vue&type=script&lang=js&"
import style0 from "./exchange.scss?vue&type=style&index=0&id=7bd1f7e1&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bd1f7e1",
  null
  
)

export default component.exports